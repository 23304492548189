<template>
  <div class="w-full flex flex-col justify-center items-center p-2 z-10 relative overflow-hidden">
      <!-- <div class="w-full text-center">
        <h6 class="text-white mt-4">Oxbull Features</h6>
      </div> -->

      <!-- <div class="sm:h-[50vw] sm:w-[50vw] h-[500px] w-[500px] absolute bg-turquoise-500 blur-[200px] rounded-full z-0 opacity-30 top-0"></div> -->


      <!-- <div class="w-full flex justify-center items-center text-7xl space-x-20 text-center mt-12 mb-48 font-brand relative z-10">
        <div class="z-10 text-white">[</div>
        <h2 class="z-10 text-turquoise-500 font-bold">Why Oxbull</h2>
        <div class="z-10 text-white">]</div>
        <div class="h-[50vw] w-[50vw] absolute bg-turquoise-500 blur-[200px] rounded-full z-0 opacity-30"></div>
      </div> -->

      <div class="flex justify-center items-center relative sm:-mt-12 py-14 w-full flex-col">
       <!-- <BracketTitle class="">
          <h2 class="z-10 text-turquoise-500 font-bold text-5xl sm:text-[7vw]">Why Oxbull</h2>
        </BracketTitle>
-->
<div class="text-center text-3xl font-brand font-bold z-20 text-turquoise-500 sm:text-[5vw] sm:space-y-12 sm:mb-8">
          <h2>Why Oxbull</h2>
        </div>
        <p class="max-w-screen-md text-center leading-10 sm:text-2xl text-base text-white">Grow Harder, Faster with Oxbull's Feature</p>

        
      </div>

      <div class="w-full grid grid-cols-12 gap-2 sm:max-w-screen-lg">




        <div class="w-full col-span-12 sm:col-span-8 sm:h-72 bg-white/10 rounded-md">
          <BentoImmunity></BentoImmunity>
        </div>
        <div class="w-full col-span-12 sm:col-span-4 h-72 bg-white/10 rounded-md">
          <BentoPrivate></BentoPrivate>
        </div>
        <div class="w-full col-span-12 h-72 sm:col-span-4 bg-white/10 rounded-md">
          <BentoApr></BentoApr>
        </div>

        <div class="w-full col-span-12 sm:col-span-4 bg-white/10 rounded-md">
          <BentoTiers></BentoTiers>
        </div>

        <div class="w-full col-span-12 sm:col-span-4 h-72 bg-white/10 rounded-md">
          <BentoCalendar></BentoCalendar>
        </div>
        <div class="w-full col-span-12 h-72 bg-white/10 rounded-md">
          <BentoGroup></BentoGroup>
        </div>
      </div>
    </div>
</template>

<script lang="ts" setup>

</script>

<style>

</style>